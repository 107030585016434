import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../assets';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gray-900 text-white py-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <div className="flex items-center">
            <img className="h-12 w-26" src={Logo} alt="Logo" />
          </div>
        </div>
          <div className="hidden md:flex md:items-center md:space-x-4">
            <a href="/" className="text-gray-200 hover:text-grey-300 border-b-2  border-b-transparent hover:border-b-2 hover:border-b-primary py-2">
              Home
            </a>
            
            <a href="https://download.fifoclock.com" className="text-gray-200 hover:text-grey-300 border-b-2  border-b-transparent hover:border-b-2 hover:border-b-primary py-2">
              Download
            </a>
            <Link to="mailto:support@fifoclock.com" className="text-gray-200 hover:text-grey-300 border-b-2  border-b-transparent hover:border-b-2 hover:border-b-primary py-2">
              Contact Us
            </Link>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-600 hover:text-grey-600 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-300">
              Home
            </Link>
            <a href="#about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-300">
              Download
            </a>
            <Link to="mailto:support@dfifoclock.com" className="block px-3 py-2 text-gray-200 hover:text-grey-300 border-b-2  border-b-transparent hover:border-b-2 hover:border-b-primary py-2">
              Contact Us
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};
