import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram} from 'react-icons/fa';
import { Logo } from '../../assets';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-900 text-white py-6">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12 mb-8 md:mb-12">
                    
                    {/* Logo Block */}
                    <div className="flex items-center justify-center md:justify-start">
                        <img className="h-12 w-auto" src={Logo} alt="Logo" />
                    </div>
    
                    {/* Site Links */}
                    <div className="text-sm flex justify-center md:justify-start">
                        <div>
                            <h6 className="font-medium uppercase mb-2">Site</h6>
                            <ul className="space-y-1">
                                <li>
                                    <Link className="text-gray-400 hover:underline transition duration-150 ease-in-out" to="/">Home</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-400 hover:underline transition duration-150 ease-in-out" to="https://download.fifoclock.com">Download</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-400 hover:underline transition duration-150 ease-in-out" to="mailto:support@fifoclock.com">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    {/* Social Links */}
                    <div className="text-sm flex justify-center md:justify-start">
                        <div>
                            <h6 className="font-medium uppercase mb-2">Socials</h6>
                            <ul className="space-y-1">
                                <li>
                                    <a
                                        className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                        href="https://www.facebook.com/fifoclockapp"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebookF className="mr-2" /> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                        href="https://www.instagram.com/fifoclockapp/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram className="mr-2" /> Instagram
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-400">
                    <p className="mb-2 md:mb-0 text-center">&copy; {currentYear} FIFO Clock From <a href="https://www.youcrew.com.au">YouCrew</a>. All rights reserved.</p>
                    
                    <p>
                        Website made by <a className="text-gray-400 hover:text-red-300" href="https://www.tokyodigital.com.au" target="_blank" rel="noopener noreferrer">Tokyo Digital</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};
