import React from "react";
import { Navbar, Footer } from "../../Components";

export const NotFoundScreen = () => {
    return (
        <div className="font-inter antialiased bg-dark text-gray-900 dark:bg-gray-900 dark:text-gray-100 tracking-tight">
            <Navbar />
            {/* Page illustration */}
            <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
                <svg className="absolute top-0 right-0 transform translate-x-1/2 -mr-16 dark:opacity-40" width="800" height="502" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="400" cy="102" r="400" fill="url(#heroglow_paint0_radial)" fillOpacity=".6" />
                <circle cx="209" cy="289" r="170" fill="url(#heroglow_paint1_radial)" fillOpacity=".4" />
                <defs>
                    <radialGradient id="heroglow_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 149 251) scale(315.089)">
                        <stop stopColor="#3ABAB4" />
                        <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                    </radialGradient>
                    <radialGradient id="heroglow_paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 -40 249) scale(133.913)">
                        <stop stopColor="#667EEA" />
                        <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />
                    </radialGradient>
                </defs>
                </svg>
            </div>
        
            <section className="relative h-screen flex items-center justify-center">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-20 text-center">
                        <div className="max-w-3xl mx-auto">
                            <h1 className="h3 text-[40px] font-red-hat-display mb-8">
                                Oops! This page is on a vacation 🏖️
                            </h1>
                            <p className="text-lg mb-8">
                                Looks like we lost it in the internet abyss. Let’s get you back on track!
                            </p>
                            <a className="btn text-white bg-primary hover:bg-teal-400 inline-flex items-center mx-auto p-3 rounded-lg" href="/">
                                <span>Back to Home</span>
                                <svg className="w-3 h-3 shrink-0 mt-px ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>



            <Footer/>
        </div>
    );
};