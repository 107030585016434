import ImageLogo from './images/Logo.png';
import ImageAboutUs1 from './images/about-us-1.png';
import ImageAboutUs2 from './images/about-us-2.png';
import ImageLabourHire from './images/labour-hire.png';
import ImageEquipmentHire from './images/equipment-hire.png';

import BackgroundVideo from './videos/hero-video.mp4';

export const HeroVideo = BackgroundVideo;

export const Logo = ImageLogo;
export const AboutUs1 = ImageAboutUs1;
export const AboutUs2 = ImageAboutUs2;
export const LabourHire = ImageLabourHire;
export const EquipmentHire = ImageEquipmentHire;