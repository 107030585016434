import React from "react";
import { Footer, Navbar } from "../../Components";

export const HomeScreen = () => {
    return (
        <>
            <div className="font-inter antialiased bg-light tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <Navbar />

                   {/* Hero Section */}
                    <section className="relative min-h-screen bg-gray-900">
                        {/* Background image */}
                        <div className="absolute inset-0 z-0 overflow-hidden">
                            <img 
                                className="absolute inset-0 w-full h-full object-cover opacity-80" 
                                src={require('../../assets/images/hero-bg.png')}
                                alt="Hero Background" 
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                        </div>

                        {/* Content */}
                        <div className="relative z-10 flex flex-col md:flex-row items-center justify-center md:justify-between w-full min-h-screen px-4 md:px-10 lg:px-20">
                            {/* Text Section */}
                            <div className="text-center md:text-left md:w-1/2 px-4 md:px-0">
                                <h1 className="text-6xl sm:text-5xl md:text-5xl lg:text-6xl font-bold mb-6 text-white text-center">
                                    <span className="text-primary">FIFO Clock:</span> Simplify Your FIFO Schedule
                                </h1>
                                <p className="text-base sm:text-2xl md:text-lg lg:text-2xl mb-6 md:mb-8 text-gray-200 leading-relaxed text-center">
                                    Effortlessly manage your roster, sync with friends, and track your time off. Get organized and make every moment count!
                                </p>
                                <div className="flex flex-row justify-center items-center">
                                    <a href="https://apps.apple.com/au/app/fifo-clock/id6466136298">
                                        <img 
                                            className="w-36 sm:w-42 md:w-36 lg:w-48 h-auto mx-2"
                                            src={require('../../assets/images/download-ios.png')}
                                            alt="Download on the App Store"
                                        />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.fifoclock&hl=en_AU">
                                        <img 
                                            className="w-36 sm:w-42 md:w-36 lg:w-48 h-auto mx-2"
                                            src={require('../../assets/images/download-android.png')}
                                            alt="Get it on Google Play"
                                        />
                                    </a>
                                </div>
                            </div>

                            {/* Image Section */}
                            <div className="w-full md:w-1/2 flex justify-center mt-20 md:mt-5">
                                <img 
                                    src={require('../../assets/images/HomeScreenImage.png')} 
                                    alt="FIFO Clock App" 
                                    className="w-full  md:max-w-md lg:max-w-2xl object-contain"
                                />
                            </div>
                        </div>
                    </section>


                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </>
    );
};
